// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beddr-js": () => import("./../../../src/pages/beddr.js" /* webpackChunkName: "component---src-pages-beddr-js" */),
  "component---src-pages-feeln-js": () => import("./../../../src/pages/feeln.js" /* webpackChunkName: "component---src-pages-feeln-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-steam-js": () => import("./../../../src/pages/steam.js" /* webpackChunkName: "component---src-pages-steam-js" */),
  "component---src-pages-vsls-js": () => import("./../../../src/pages/vsls.js" /* webpackChunkName: "component---src-pages-vsls-js" */)
}

